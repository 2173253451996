export const LOAD_ALL_QUESTIONS_REQUEST = "LOAD_ALL_QUESTIONS_REQUEST";
export const LOAD_ALL_QUESTIONS_SUCCESS = "LOAD_ALL_QUESTIONS_SUCCESS";
export const LOAD_ALL_QUESTIONS_FAIL = "LOAD_ALL_QUESTIONS_FAIL";

export const QUESTION_DETAIL_REQUEST = "QUESTION_DETAIL_REQUEST";
export const QUESTION_DETAIL_SUCCESS = "QUESTION_DETAIL_SUCCESS";
export const QUESTION_DETAIL_FAIL = "QUESTION_DETAIL_FAIL";

export const ADD_QUESTION_REQUEST = "ADD_QUESTION_REQUEST";
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS";
export const ADD_QUESTION_RESET = "ADD_QUESTION_RESET";
export const ADD_QUESTION_FAIL = "ADD_QUESTION_FAIL";

export const UPDATE_QUESTION_REQUEST = "UPDATE_QUESTION_REQUEST";
export const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS";
export const UPDATE_QUESTION_RESET = "UPDATE_QUESTION_RESET";
export const UPDATE_QUESTION_FAIL = "UPDATE_QUESTION_FAIL";
export const DELETE_QUESTION_REQUEST = "DELETE_QUESTION_REQUEST";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_FAIL = "DELETE_QUESTION_FAIL";

// question subjects
export const LOAD_QUESTION_SUBJECTS_REQUEST = "LOAD_QUESTION_SUBJECTS_REQUEST";
export const LOAD_QUESTION_SUBJECTS_SUCCESS = "LOAD_QUESTION_SUBJECTS_SUCCESS";
export const LOAD_QUESTION_SUBJECTS_FAIL = "LOAD_QUESTION_SUBJECTS_FAIL";

export const ADD_QUESTION_SUBJECT_REQUEST = "ADD_QUESTION_SUBJECT_REQUEST";
export const ADD_QUESTION_SUBJECT_SUCCESS = "ADD_QUESTION_SUBJECT_SUCCESS";
export const ADD_QUESTION_SUBJECT_RESET = "ADD_QUESTION_SUBJECT_RESET";
export const ADD_QUESTION_SUBJECT_FAIL = "ADD_QUESTION_SUBJECT_FAIL";

export const SUBJECT_YEARS_REQUEST = "SUBJECT_YEARS_REQUEST";
export const SUBJECT_YEARS_SUCCESS = "SUBJECT_YEARS_SUCCESS";
export const SUBJECT_YEARS_FAIL = "SUBJECT_YEARS_FAIL";

export const SUBJECT_YEAR_DETAIL_REQUEST = "SUBJECT_YEAR_DETAIL_REQUEST";
export const SUBJECT_YEAR_DETAIL_SUCCESS = "SUBJECT_YEAR_DETAIL_SUCCESS";
export const SUBJECT_YEAR_DETAIL_FAIL = "SUBJECT_YEAR_DETAIL_FAIL";

export const UPDATE_SUBJECT_YEAR_REQUEST = "UPDATE_SUBJECT_YEAR_REQUEST";
export const UPDATE_SUBJECT_YEAR_SUCCESS = "UPDATE_SUBJECT_YEAR_SUCCESS";
export const UPDATE_SUBJECT_YEAR_RESET = "UPDATE_SUBJECT_YEAR_RESET";
export const UPDATE_SUBJECT_YEAR_FAIL = "UPDATE_SUBJECT_YEAR_FAIL";

// question topic sections
export const GET_TOPIC_SECTIONS_REQUEST = "GET_TOPIC_SECTIONS_REQUEST";
export const GET_TOPIC_SECTIONS_SUCCESS = "GET_TOPIC_SECTIONS_SUCCESS";
export const GET_TOPIC_SECTIONS_FAIL = "GET_TOPIC_SECTIONS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
