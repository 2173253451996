import {
	COURSE_SUBJECTS_REQUEST,
	COURSE_SUBJECTS_SUCCESS,
	COURSE_SUBJECTS_FAIL,
	REGISTERED_SUBJECTS_REQUEST,
	REGISTERED_SUBJECTS_SUCCESS,
	REGISTERED_SUBJECTS_FAIL,
	GET_SUBJECT_REQUEST,
	GET_SUBJECT_SUCCESS,
	GET_SUBJECT_FAIL,
	SAVE_SUBJECT_REQUEST,
	SAVE_SUBJECT_SUCCESS,
	SAVE_SUBJECT_RESET,
	SAVE_SUBJECT_FAIL,
	GET_SAVED_SUBJECTS_REQUEST,
	GET_SAVED_SUBJECTS_SUCCESS,
	GET_SAVED_SUBJECTS_FAIL,
	CLEAR_ERRORS
} from './../constants/subjectConstants';

export const courseSubjectsReducer = (state = { courseSubjects: [], regSubjects: [], savedSubjects: [] }, action) => {
	switch (action.type) {
		case COURSE_SUBJECTS_REQUEST:
			return {
				csloading: true,
				courseSubjects: []
			};

		case COURSE_SUBJECTS_SUCCESS:
			return {
				csloading: false,
				courseSubjects: action.payload.data
			};

		case COURSE_SUBJECTS_FAIL:
			return {
				csloading: false,
				error: action.payload
			};

		case REGISTERED_SUBJECTS_REQUEST:
			return {
				csloading: true,
				regSubjects: []
			};

		case REGISTERED_SUBJECTS_SUCCESS:
			return {
				csloading: false,
				regSubjects: action.payload.subjects
			};

		case REGISTERED_SUBJECTS_FAIL:
			return {
				csloading: false,
				error: action.payload
			};

		/**
       * Save Subject to user account
       */
		case SAVE_SUBJECT_REQUEST:
			return {
				sloading: true,
				isSaved: false
			};

		case SAVE_SUBJECT_SUCCESS:
			return {
				...state,
				sloading: false,
				isSaved: true,
				subject: action.payload
			};

		case SAVE_SUBJECT_RESET:
			return {
				...state,
				isSaved: false
			};

		case SAVE_SUBJECT_FAIL:
			return {
				...state,
				sloading: false,
				isSaved: false,
				subject: null,
				error: action.payload
			};

		case GET_SAVED_SUBJECTS_REQUEST:
			return {
				csloading: true,
				savedSubjects: []
			};

		case GET_SAVED_SUBJECTS_SUCCESS:
			return {
				csloading: false,
				savedSubjects: action.payload.subjects
			};

		case GET_SAVED_SUBJECTS_FAIL:
			return {
				csloading: false,
				error: action.payload
			};

		/**
       * END
       */

		case CLEAR_ERRORS:
			return {
				...state,
				error: null
			};

		default:
			return state;
	}
};

export const singleSubjectReducer = (state = { subjectData: '' }, action) => {
	switch (action.type) {
		case GET_SUBJECT_REQUEST:
			return {
				...state,
				sloading: true
			};

		case GET_SUBJECT_SUCCESS:
			return {
				sloading: true,
				subjectData: action.payload
			};

		case GET_SUBJECT_FAIL:
			return {
				...state,
				error: action.payload
			};

		case CLEAR_ERRORS:
			return {
				...state,
				error: null
			};

		default:
			return state;
	}
};
