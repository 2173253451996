export const LOAD_ALL_SUBSCRIPTIONS_REQUEST = "LOAD_ALL_SUBSCRIPTIONS_REQUEST";
export const LOAD_ALL_SUBSCRIPTIONS_SUCCESS = "LOAD_ALL_SUBSCRIPTIONS_SUCCESS";
export const LOAD_ALL_SUBSCRIPTIONS_FAIL = "LOAD_ALL_SUBSCRIPTIONS_FAIL";

export const ADD_SUBSCRIPTION_REQUEST = "ADD_SUBSCRIPTION_REQUEST";
export const ADD_SUBSCRIPTION_SUCCESS = "ADD_SUBSCRIPTION_SUCCESS";
export const ADD_SUBSCRIPTION_RESET = "ADD_SUBSCRIPTION_RESET";
export const ADD_SUBSCRIPTION_FAIL = "ADD_SUBSCRIPTION_FAIL";

export const SUBSCRIPTION_DETAIL_REQUEST = "SUBSCRIPTION_DETAIL_REQUEST";
export const SUBSCRIPTION_DETAIL_SUCCESS = "SUBSCRIPTION_DETAIL_SUCCESS";
export const SUBSCRIPTION_DETAIL_FAIL = "SUBSCRIPTION_DETAIL_FAIL";

export const UPDATE_SUBSCRIPTION_REQUEST = "UPDATE_SUBSCRIPTION_REQUEST";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";
export const UPDATE_SUBSCRIPTION_RESET = "UPDATE_SUBSCRIPTION_RESET";
export const UPDATE_SUBSCRIPTION_FAIL = "UPDATE_SUBSCRIPTION_FAIL";
export const DELETE_SUBSCRIPTION_REQUEST = "DELETE_SUBSCRIPTION_REQUEST";
export const DELETE_SUBSCRIPTION_SUCCESS = "DELETE_SUBSCRIPTION_SUCCESS";
export const DELETE_SUBSCRIPTION_FAIL = "DELETE_SUBSCRIPTION_FAIL";

/**
 * List all
 */
export const LIST_STUDENT_SUBSCRIPTIONS_REQUEST =
  "LIST_STUDENT_SUBSCRIPTIONS_REQUEST";
export const LIST_STUDENT_SUBSCRIPTIONS_SUCCESS =
  "LIST_STUDENT_SUBSCRIPTIONS_SUCCESS";
export const LIST_STUDENT_SUBSCRIPTIONS_FAIL =
  "LIST_STUDENT_SUBSCRIPTIONS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
