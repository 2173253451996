export const LOAD_ALL_SUBSCRIBE_NEWSLETTER_REQUEST = "LOAD_ALL_SUBSCRIBE_NEWSLETTER_REQUEST";
export const LOAD_ALL_SUBSCRIBE_NEWSLETTER_SUCCESS = "LOAD_ALL_SUBSCRIBE_NEWSLETTER_SUCCESS";
export const LOAD_ALL_SUBSCRIBE_NEWSLETTER_FAIL = "LOAD_ALL_SUBSCRIBE_NEWSLETTER_FAIL";

export const ADD_SUBSCRIBE_NEWSLETTER_REQUEST = "ADD_SUBSCRIBE_NEWSLETTER_REQUEST";
export const ADD_SUBSCRIBE_NEWSLETTER_SUCCESS = "ADD_SUBSCRIBE_NEWSLETTER_SUCCESS";
export const ADD_SUBSCRIBE_NEWSLETTER_RESET = "ADD_SUBSCRIBE_NEWSLETTER_RESET";
export const ADD_SUBSCRIBE_NEWSLETTER_FAIL = "ADD_SUBSCRIBE_NEWSLETTER_FAIL";

export const DELETE_SUBSCRIBE_NEWSLETTER_REQUEST = "DELETE_SUBSCRIBE_NEWSLETTER_REQUEST";
export const DELETE_SUBSCRIBE_NEWSLETTER_SUCCESS = "DELETE_SUBSCRIBE_NEWSLETTER_SUCCESS";
export const DELETE_SUBSCRIBE_NEWSLETTER_FAIL = "DELETE_SUBSCRIBE_NEWSLETTER_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
