import {
  GET_ALL_ETEMPLATES,
  GET_SINGLE_ETEMPLATE,
  UPDATE_ETEMPLATE_REQUEST,
  UPDATE_ETEMPLATE_SUCCESS,
  UPDATE_ETEMPLATE_RESET,
  UPDATE_ETEMPLATE_FAILED,
    
  CLEAR_ERRORS  
} from "./../../constants/admin/emailTemplateConstants";

const initialState = {
  emailtemplates: [],
  emailtemplate: {},
  etemplateLoading: true,
};

export const emailtemplatesReducers = (state = initialState, action) => {
  switch (action.type) {

    case GET_ALL_ETEMPLATES:
      return {
        ...state,
        emailtemplates: action.payload.emailtemplates,
        etemplateLoading: false
      };

    /**
     * GET ETEMPLATE DATA
     */
    case GET_SINGLE_ETEMPLATE:
      return {
        ...state,
        etemplateLoading: false,
        emailtemplate: action.payload,
      };

    /**
     * GET ETEMPLATE DATA
     */

    /**
     * UPDATE ETEMPLATE REQUEST
     */

     case UPDATE_ETEMPLATE_REQUEST:
      return {
        ...state,
        etemplateLoading: true,
        errors: null,
      };

    case UPDATE_ETEMPLATE_SUCCESS:
      return {
        ...state,
        etemplateLoading: false,
        isUpdated: action.payload,
        errors: null,
      };

    case UPDATE_ETEMPLATE_RESET:
      return {
        ...state,
        isUpdated: false,
        errors: null,
      };

    case UPDATE_ETEMPLATE_FAILED:
      return {
        ...state,
        etemplateLoading: false,
        error: action.payload,
        errors: action.errors,
      };
    /**
     * UPDATE ETEMPLATE REQUEST
     */

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};