import axios from "axios";

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  CLEAR_ERRORS,
} from "../constants/authConstants";

const _api_path = "/api/v1";

//login
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      _api_path + "/login",
      { email, password },
      config
    );
    if (!data) {
      dispatch({
        type: LOGIN_FAIL,
        payload: "Invalid email or password",
      });
    }
    dispatch({
      type: LOGIN_SUCCESS,
      payload: data.user,
    });
  } catch (error) {
    // console.log('error.response ', error.response); return;
    dispatch({
      type: LOGIN_FAIL,
      payload: error.response.data.errMessage,
      errors: error.response.data,
    });
  }
};

//Register User
export const register =
  (
    first_name,
    last_name,
    email,
    dob,
    gender,
    password,
    confirm_password,
    mobile_number,
    mobile_code,
    nationality
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: REGISTER_USER_REQUEST,
      });
      const config = {
        headers: {
          //"content-type": "multipart/form-data",
          "content-type": "application/json",
        },
      };
      // console.log("userData ", userData.getAll());
      // return;
      const { data } = await axios.post(
        _api_path + "/register",
        // userData,
        {
          first_name,
          last_name,
          email,
          dob,
          gender,
          password,
          confirm_password,
          mobile_number,
          mobile_code,
          nationality,
        },
        config
      );
      // console.log('data', data); return;
      /*if (!data) {
          dispatch({
            type: REGISTER_USER_FAIL,
            payload: "Invalid email or password",
          });
        }*/
      dispatch({
        type: REGISTER_USER_SUCCESS,
        payload: data.user,
      });
    } catch (error) {
      //console.log("error.response", error.response.error);
      var errorMsg = error.response.data.errMessage;
      if (
        error.response.data.error !== undefined &&
        error.response.data.error.code === 11000
      ) {
        errorMsg = "Entered email already exist, please try another.";
      }
      dispatch({
        type: REGISTER_USER_FAIL,
        payload: errorMsg,
        errors: error.response.data,
      });
    }
  };

//Load user
export const loadUser = (e) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_USER_REQUEST,
    });
    const { data } = await axios.get(_api_path + "/me");

    dispatch({
      type: LOAD_USER_SUCCESS,
      payload: data.user,
    });
  } catch (error) {
    // console.log(error.response.statusText);
    dispatch({
      type: LOAD_USER_FAIL,
      payload: error.response.data.errMessage,
      // payload: error.response.statusText
    });
  }
};

//admin Forgot Password
export const adminForgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      _api_path + "/admin/forgot-password",
      { email },
      config
    );

    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: error.response.data.errMessage,
      errors: error.response.data,
    });
  }
};

//Forgot Password
export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      _api_path + "/password/forgot",
      { email },
      config
    );

    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: error.response.data.errMessage,
      errors: error.response.data,
    });
  }
};

//Reset / New Password
export const resetPassword =
  (token, password, confirm_password) => async (dispatch) => {
    try {
      dispatch({
        type: RESET_PASSWORD_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.put(
        _api_path + `/password/reset/${token}`,
        { password, confirm_password },
        config
      );

      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: data.success,
      });
    } catch (error) {
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: error.response.data.errMessage,
        errors: error.response.data,
      });
    }
  };

//log out
export const logoutUser = () => async (dispatch) => {
  try {
    await axios.get("/api/v1/logout");

    dispatch({
      type: LOGOUT_SUCCESS,
      payload: "logout",
    });
  } catch (err) {
    dispatch({
      type: LOGOUT_FAIL,
      payload: err.response.data.errMessage,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
