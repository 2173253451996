import { createStore, combineReducers, applyMiddleware } from "redux";

import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
    homeContentReducers
} from "./reducers/admin/homeSettingReducers";

import {
    cmsListReducer,
    cmsDetailReducer,
    cmsReducer,
} from "./reducers/cmsReducers";

import {
    emailtemplatesReducers
} from "./reducers/admin/emailTemplateReducers";
import {
    bannersReducers
} from "./reducers/admin/bannerReducers";

import { authReducer, forgotPasswordReducer } from "./reducers/authReducers";
import { userReducer } from "./reducers/userReducers";
import { usersReducer, addUserReducer } from "./reducers/adminUserReducers";
import {
    studentsReducer,
    addStudentReducer,
    deleteStudentReducer,
} from "./reducers/adminStudentReducers";
import {
    commonReducer,
    commonCategoriesReducer,
    commonExamsReducer,
    commonCoursesReducer,
    commonSubjectsReducer,
    getUserDataReducer,
    deleteUserReducer,
} from "./reducers/commonReducers";
import {
    categoriesReducer,
    categoryDetailReducer,
    deleteCategoryReducer,
} from "./reducers/adminCategoryReducers";
import { examsReducer, examDetailReducer } from "./reducers/adminExamReducers";
import {
    subscriptionsReducer,
    subscriptionDetailReducer,
} from "./reducers/adminSubscriptionReducers";

import {
    assessmentsReducer,
    assessmentDetailReducer,
    deleteAssessmentReducer,
} from "./reducers/adminAssessmentReducers";

import {
    coursesReducer,
    courseDetailReducer,
    deleteCourseReducer,
} from "./reducers/adminCourseReducers";

import {
    subjectsReducer,
    subjectDetailReducer,
} from "./reducers/adminSubjectReducers";

import {
    topicsReducer,
    topicDetailReducer,
    deleteTopicReducer,
} from "./reducers/adminTopicReducers";

import {
    topicSectionsReducer,
    topicSectionDetailReducer,
    deleteTopicSectionReducer,
} from "./reducers/adminTopicSectionReducers";

import {
    theoryListReducer,
    theoryDetailReducer,
    deleteTheoryReducer,
} from "./reducers/adminTheoryReducers";

import {
    questionsReducer,
    questionDetailReducer,
    questionSubjectDetailReducer,
} from "./reducers/adminQuestionReducers";

import {
    newsListReducer,
    newsDetailReducer,
    deleteNewsReducer,
} from "./reducers/adminNewsReducers";

import {
    adviceListReducer,
    adviceDetailReducer,
    deleteAdviceReducer,
} from "./reducers/adminAdviceReducers";

import {
    testimonialListReducer,
    testimonialDetailReducer,
    deleteTestimonialReducer,
} from "./reducers/adminTestimonialReducers";

import {
    instructionsReducer,
    instructionDetailReducer
} from "./reducers/adminInstructionReducers";

import { allNewsListReducer, singleNewsReducer } from "./reducers/newsReducers";
import { examSubscriptionsReducer } from "./reducers/subscriptionReducers";
import { categoryCoursesReducer } from "./reducers/courseReducers";
import {
    courseSubjectsReducer,
    singleSubjectReducer,
} from "./reducers/subjectReducers";
import {
    assessmentsListReducer,
    singleAssessmentReducer,
} from "./reducers/assessmentReducers";

import { yearQuestionsReducer } from "./reducers/questionReducers";

import { contactusReducer, deleteContactusReducer, contactusDetailReducer, addContactusReducer, contactusReplyDetailReducer } from "./reducers/adminContactusReducers";

import {subscribeNewsletterReducer} from "./reducers/adminSubscribeNewsletterReducers";
//result reducer
import { resultsReducer } from "./reducers/resultReducers";
//mock
import { mockListReducer } from "./reducers/mockReducers";

import { homeTestimonialsReducer } from "./reducers/homeReducers";

const reducer = combineReducers({
    homeContent: homeContentReducers,
    cmsList: cmsListReducer,
    cmsDetail: cmsDetailReducer,
    cmspage: cmsReducer,
    banners: bannersReducers,
    emailtemplates: emailtemplatesReducers,
    auth: authReducer,
    user: userReducer,
    users: usersReducer,
    addUser: addUserReducer,
    students: studentsReducer,
    addStudent: addStudentReducer,
    deleteStudent: deleteStudentReducer,
    userData: getUserDataReducer,
    deleteUser: deleteUserReducer,
    forgotPassword: forgotPasswordReducer,

    categories: categoriesReducer,
    categoryDetail: categoryDetailReducer,
    deleteCategory: deleteCategoryReducer,

    exams: examsReducer,
    examDetail: examDetailReducer,

    subscriptions: subscriptionsReducer,
    subscriptionDetail: subscriptionDetailReducer,

    commonData: commonReducer,

    assessments: assessmentsReducer,
    assessmentDetail: assessmentDetailReducer,
    deleteAssessment: deleteAssessmentReducer,

    courses: coursesReducer,
    courseDetail: courseDetailReducer,
    deleteCourse: deleteCourseReducer,

    subjects: subjectsReducer,
    subjectDetail: subjectDetailReducer,

    commonCategories: commonCategoriesReducer,
    commonExams: commonExamsReducer,
    commonCourses: commonCoursesReducer,
    commonSubjects: commonSubjectsReducer,

    topics: topicsReducer,
    topicDetail: topicDetailReducer,
    deleteTopic: deleteTopicReducer,

    topicSections: topicSectionsReducer,
    topicSectionDetail: topicSectionDetailReducer,
    deleteTopicSection: deleteTopicSectionReducer,

    questions: questionsReducer,
    questionDetail: questionDetailReducer,
    questionSubjectDetail: questionSubjectDetailReducer,

    theoryList: theoryListReducer,
    theoryDetail: theoryDetailReducer,
    deleteTheory: deleteTheoryReducer,

    newsList: newsListReducer,
    newsDetail: newsDetailReducer,
    deleteNews: deleteNewsReducer,

    adviceList: adviceListReducer,
    adviceDetail: adviceDetailReducer,
    deleteAdvice: deleteAdviceReducer,

    testimonialList: testimonialListReducer,
    testimonialDetail: testimonialDetailReducer,
    deleteTestimonial: deleteTestimonialReducer,

    instructionList: instructionsReducer,
    instructionDetail: instructionDetailReducer,

    contactusList:contactusReducer,
    contactusDetail:contactusDetailReducer,
    deleteContactus: deleteContactusReducer,
    contactAdd: addContactusReducer,
    contactusReplyDetail:contactusReplyDetailReducer,

    subscribeNewsletterList:subscribeNewsletterReducer,


    //frontend reducer
    examSubscriptions: examSubscriptionsReducer,
    allNewsList: allNewsListReducer,
    singleNews: singleNewsReducer,

    categoryCourses: categoryCoursesReducer,
    courseSubjects: courseSubjectsReducer,
    singleSubject: singleSubjectReducer,

    assessmentsList: assessmentsListReducer,
    singleAssessment: singleAssessmentReducer,

    yearQuestions: yearQuestionsReducer,

    //results reducer
    results: resultsReducer,

    mockList: mockListReducer,

    homeTestimonials: homeTestimonialsReducer
});

let initialState = {};

const middleware = [thunk];

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
