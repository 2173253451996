import {
  LOAD_ALL_THEORY_REQUEST,
  LOAD_ALL_THEORY_SUCCESS,
  LOAD_ALL_THEORY_FAIL,
  ADD_THEORY_REQUEST,
  ADD_THEORY_SUCCESS,
  ADD_THEORY_RESET,
  ADD_THEORY_FAIL,
  THEORY_DETAIL_REQUEST,
  THEORY_DETAIL_SUCCESS,
  THEORY_DETAIL_FAIL,
  UPDATE_THEORY_REQUEST,
  UPDATE_THEORY_SUCCESS,
  UPDATE_THEORY_RESET,
  UPDATE_THEORY_FAIL,
  DELETE_THEORY_REQUEST,
  DELETE_THEORY_SUCCESS,
  DELETE_THEORY_FAIL,

  GET_THEORY_EXAM_REQUEST,
  GET_THEORY_EXAM_SUCCESS,
  GET_THEORY_EXAM_FAIL,

  CLEAR_ERRORS,
} from "./../constants/adminTheoryConstants";

export const theoryListReducer = (state = { theoryList: [], userTheoryList: [] }, action) => {
  switch (action.type) {
    case LOAD_ALL_THEORY_REQUEST:
      return {
        tloading: true,
        theories: [],
      };

    case LOAD_ALL_THEORY_SUCCESS:
      return {
        tloading: false,
        theories: action.payload.theories,
      };

    case LOAD_ALL_THEORY_FAIL:
      return {
        tloading: false,
        error: action.payload,
      };

    case UPDATE_THEORY_REQUEST:
      return {
        ...state,
        tloading: true,
        errors: null,
      };

    case UPDATE_THEORY_SUCCESS:
      return {
        ...state,
        tloading: false,
        isUpdated: action.payload,
      };

    case UPDATE_THEORY_RESET:
      return {
        ...state,
        isUpdated: false,
        errors: null,
      };

    case UPDATE_THEORY_FAIL:
      return {
        ...state,
        tloading: false,
        error: action.payload,
        errors: action.errors,
      };

      /**
       * User Theory
       */
       case GET_THEORY_EXAM_REQUEST:
        return {
          tloading: true,
          userTheoryList: [],
        };
  
      case GET_THEORY_EXAM_SUCCESS:
        return {
          tloading: false,
          userTheoryList: action.payload.results,
        };
  
      case GET_THEORY_EXAM_FAIL:
        return {
          tloading: false,
          error: action.payload,
        };
      /**
       * end
       */

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const theoryDetailReducer = (state = { theory: {} }, action) => {
  switch (action.type) {
    case THEORY_DETAIL_REQUEST:
      return {
        ...state,
        tloading: true,
      };

    case THEORY_DETAIL_SUCCESS:
      return {
        tloading: true,
        theory: action.payload,
      };

    case THEORY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_THEORY_REQUEST:
      return {
        tloading: true,
        isTheoryAdded: false,
        errors: null,
      };

    case ADD_THEORY_SUCCESS:
      return {
        ...state,
        tloading: false,
        isTheoryAdded: true,
        theory: action.payload,
        errors: null,
      };

    case ADD_THEORY_RESET:
      return {
        ...state,
        isTheoryAdded: false,
        errors: null,
      };

    case ADD_THEORY_FAIL:
      return {
        ...state,
        tloading: false,
        isTheoryAdded: false,
        theory: null,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const deleteTheoryReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_THEORY_REQUEST:
      return {
        tloading: true,
      };

    case DELETE_THEORY_SUCCESS:
      return state.filter((item) => String(item.id) !== String(action.payload));

    case DELETE_THEORY_FAIL:
      return {
        tloading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
