import React, { Fragment } from "react";

import swal from "sweetalert";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useDispatch } from "react-redux";
import { addContactus } from "../../actions/adminContactusActions";
import AuthCommonBanner from "./../Auth/AuthCommonBanner";

// form validation rules
const validationSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().required("Email is required").email("Email is invalid"),
  mobileNo: yup
    .string()
    .min(10, "Mobile no must be at least 10 digits")
    .required("Mobile no is required"),
  subject: yup.string().required("Subject is required"),
  message: yup.string().required("Message is required"),
});

const ContactUsFront = () => {
  const dispatch = useDispatch();

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const submitForm = (data, e) => {
    dispatch(
      addContactus(
        data.firstName,
        data.lastName,
        data.mobileNo,
        data.email,
        data.subject,
        data.message
      )
    );
    e.target.reset();
    swal(
      "Contact Us Success",
      "Thanks for contacting us, we will get back to you soon.",
      "success"
    );
  };

  const resetForm = (e) => {
    e.target.reset();
  };

  return (
    <Fragment>
      <section className="registration-page clearfix fullHt">
        <div className="right-regi-step login-page">
          <div className="vh-center">
            <h1>
              <span>Contact Us</span>
            </h1>
            <p className="pull-right">
              <span className="sub">*</span> is mandatory fields
            </p>
            <div className="inner-que">
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="firstName">
                        First Name<span className="sub">*</span>
                      </label>
                      <input
                        ref={register}
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        name="firstName"
                      />
                      <div className="invalid-feedback">
                        {errors.firstName?.message}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="lastName">
                        Last Name<span className="sub">*</span>
                      </label>
                      <input
                        ref={register}
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        name="lastName"
                      />
                      <div className="invalid-feedback">
                        {errors.lastName?.message}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="mobileNo">
                        Mobile No<span className="sub">*</span>
                      </label>
                      <input
                        ref={register}
                        type="let"
                        className="form-control"
                        autoComplete="off"
                        name="mobileNo"
                      />
                      <div className="invalid-feedback">
                        {errors.mobileNo?.message}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email">
                        Email<span className="sub">*</span>
                      </label>
                      <input
                        ref={register}
                        type="email"
                        className="form-control"
                        autoComplete="off"
                        name="email"
                      />
                      <div className="invalid-feedback">
                        {errors.email?.message}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="subject">
                        Subject<span className="sub">*</span>
                      </label>
                      <input
                        ref={register}
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        name="subject"
                      />
                      <div className="invalid-feedback">
                        {errors.subject?.message}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="message">
                        Message<span className="sub">*</span>
                      </label>
                      <textarea
                        ref={register}
                        type="message"
                        className="form-control"
                        name="message"
                      ></textarea>
                      <div className="invalid-feedback">
                        {errors.message?.message}
                      </div>
                    </div>
                  </div>
                </div>
                <ul className="log-btn-main clearfix">
                  <li className="pull-right">
                    <button
                      type="reset"
                      onClick={resetForm}
                      className="btn btn-default"
                    >
                      Reset
                    </button>{" "}
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
        <AuthCommonBanner></AuthCommonBanner>
      </section>
    </Fragment>
  );
};

export default ContactUsFront;
