import {
  LOAD_ALL_INSTRUCTIONS_REQUEST,
  LOAD_ALL_INSTRUCTIONS_SUCCESS,
  LOAD_ALL_INSTRUCTIONS_FAIL,
  ADD_INSTRUCTION_REQUEST,
  ADD_INSTRUCTION_SUCCESS,
  ADD_INSTRUCTION_RESET,
  ADD_INSTRUCTION_FAIL,
  INSTRUCTION_DETAIL_REQUEST,
  INSTRUCTION_DETAIL_SUCCESS,
  INSTRUCTION_DETAIL_FAIL,
  UPDATE_INSTRUCTION_REQUEST,
  UPDATE_INSTRUCTION_SUCCESS,
  UPDATE_INSTRUCTION_RESET,
  UPDATE_INSTRUCTION_FAIL,
  
  CLEAR_ERRORS,
} from "./../constants/adminInstructionConstants";

export const instructionsReducer = (state = { instructions: [] }, action) => {
  switch (action.type) {
    case LOAD_ALL_INSTRUCTIONS_REQUEST:
      return {
        iLoading: true,
        instructions: [],
      };

    case LOAD_ALL_INSTRUCTIONS_SUCCESS:
      return {
        iLoading: false,
        instructions: action.payload.data,
      };

    case LOAD_ALL_INSTRUCTIONS_FAIL:
      return {
        iLoading: false,
        error: action.payload,
      };

    case UPDATE_INSTRUCTION_REQUEST:
      return {
        ...state,
        iLoading: true,
        errors: null,
      };

    case UPDATE_INSTRUCTION_SUCCESS:
      return {
        ...state,
        iLoading: false,
        isUpdated: action.payload,
      };

    case UPDATE_INSTRUCTION_RESET:
      return {
        ...state,
        isUpdated: false,
        errors: null,
      };

    case UPDATE_INSTRUCTION_FAIL:
      return {
        ...state,
        iLoading: false,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const instructionDetailReducer = (state = { instruction: {} }, action) => {
  switch (action.type) {
    case INSTRUCTION_DETAIL_REQUEST:
      return {
        ...state,
        iLoading: true,
      };

    case INSTRUCTION_DETAIL_SUCCESS:
      return {
        iLoading: true,
        instruction: action.payload,
      };

    case INSTRUCTION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_INSTRUCTION_REQUEST:
      return {
        iLoading: true,
        isInstructionAdded: false,
        errors: null,
      };

    case ADD_INSTRUCTION_SUCCESS:
      return {
        ...state,
        iLoading: false,
        isInstructionAdded: true,
        instruction: action.payload,
        errors: null,
      };

    case ADD_INSTRUCTION_RESET:
      return {
        ...state,
        isInstructionAdded: false,
        errors: null,
      };

    case ADD_INSTRUCTION_FAIL:
      return {
        ...state,
        iLoading: false,
        isInstructionAdded: false,
        instruction: null,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
