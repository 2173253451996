import {
    GET_TESTIMONIALS_REQUEST,
    GET_TESTIMONIALS_SUCCESS,
    GET_TESTIMONIALS_FAIL,
    CLEAR_ERRORS,
} from "../constants/homeConstants";

export const homeTestimonialsReducer = (state = { testimonials: [] }, action) => {
    switch (action.type) {
        case GET_TESTIMONIALS_REQUEST:
            return {
                tloading: true,
                testimonials: []
            };

        case GET_TESTIMONIALS_SUCCESS:
            return {
                tloading: false,
                testimonials: action.payload
            };

        case GET_TESTIMONIALS_FAIL:
            return {
                tloading: false,
                error: action.payload
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };

        default:
            return state;
    }
};