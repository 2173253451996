import "./App.css";

function App() {
  return (
    <article style={{ textAlign: 'center'}}>
      <h1>We&rsquo;ll be back soon!</h1>
      <div>
        <p>
          Sorry for the inconvenience but we&rsquo;re performing some
          maintenance at the moment. we&rsquo;ll be back online shortly!
        </p>
        <p>&mdash; OTS Team</p>
      </div>
    </article>
  );
}

export default App;
