export const LOAD_ALL_INSTRUCTIONS_REQUEST = "LOAD_ALL_INSTRUCTIONS_REQUEST";
export const LOAD_ALL_INSTRUCTIONS_SUCCESS = "LOAD_ALL_INSTRUCTIONS_SUCCESS";
export const LOAD_ALL_INSTRUCTIONS_FAIL = "LOAD_ALL_INSTRUCTIONS_FAIL";

export const INSTRUCTION_DETAIL_REQUEST = "INSTRUCTION_DETAIL_REQUEST";
export const INSTRUCTION_DETAIL_SUCCESS = "INSTRUCTION_DETAIL_SUCCESS";
export const INSTRUCTION_DETAIL_FAIL = "INSTRUCTION_DETAIL_FAIL";

export const ADD_INSTRUCTION_REQUEST = "ADD_INSTRUCTION_REQUEST";
export const ADD_INSTRUCTION_SUCCESS = "ADD_INSTRUCTION_SUCCESS";
export const ADD_INSTRUCTION_RESET = "ADD_INSTRUCTION_RESET";
export const ADD_INSTRUCTION_FAIL = "ADD_INSTRUCTION_FAIL";

export const UPDATE_INSTRUCTION_REQUEST = "UPDATE_INSTRUCTION_REQUEST";
export const UPDATE_INSTRUCTION_SUCCESS = "UPDATE_INSTRUCTION_SUCCESS";
export const UPDATE_INSTRUCTION_RESET = "UPDATE_INSTRUCTION_RESET";
export const UPDATE_INSTRUCTION_FAIL = "UPDATE_INSTRUCTION_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
