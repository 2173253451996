import {
  GET_HOME_CONTENT,
  UPDATE_HOME_CONTENT_REQUEST,
  UPDATE_HOME_CONTENT_SUCCESS,
  UPDATE_HOME_CONTENT_RESET,
  UPDATE_HOME_CONTENT_FAILED,

  CLEAR_ERRORS,
} from "./../../constants/admin/homeSettingConstants";

const initialState = {
  homeContent: {},
  hLoading: true,
};

export const homeContentReducers = (state = initialState, action) => {
  switch (action.type) {
    /**
     * GET HOME_CONTENT DATA
     */
    case GET_HOME_CONTENT:
      return {
        ...state,
        hLoading: false,
        homeContent: action.payload.data,
      };

    /**
     * UPDATE HOME_CONTENT REQUEST
     */

    case UPDATE_HOME_CONTENT_REQUEST:
      return {
        ...state,
        hLoading: true,
        errors: null,
      };

    case UPDATE_HOME_CONTENT_SUCCESS:
      return {
        ...state,
        hLoading: false,
        isUpdated: action.payload,
        errors: null,
      };

    case UPDATE_HOME_CONTENT_RESET:
      return {
        ...state,
        isUpdated: false,
        errors: null,
      };

    case UPDATE_HOME_CONTENT_FAILED:
      return {
        ...state,
        hLoading: false,
        error: action.payload,
        errors: action.errors,
      };
    /**
     * UPDATE HOME_CONTENT REQUEST
     */

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
