import {
	GET_EXAM_INSTRUCTION_REQUEST,
	GET_EXAM_INSTRUCTION_SUCCESS,
	GET_EXAM_INSTRUCTION_FAIL,
	MOCK_EXAM_QUESTIONS_REQUEST,
	MOCK_EXAM_QUESTIONS_SUCCESS,
	MOCK_EXAM_QUESTIONS_FAIL,
	CLEAR_ERRORS
} from './../constants/mockConstants';

export const mockListReducer = (state = { instruction: {}, mockQuestions: [] }, action) => {
	switch (action.type) {
		case GET_EXAM_INSTRUCTION_REQUEST:
			return {
				iloading: true,
				instruction: {}
			};

		case GET_EXAM_INSTRUCTION_SUCCESS:
			return {
				iloading: false,
				instruction: action.payload.instruction
			};

		case GET_EXAM_INSTRUCTION_FAIL:
			return {
				iloading: false,
				error: action.payload
			};

		/**
       * Get mock questions
       */
		case MOCK_EXAM_QUESTIONS_REQUEST:
			return {
				mloading: true,
				mockQuestions: {}
			};

		case MOCK_EXAM_QUESTIONS_SUCCESS:
			return {
				mloading: false,
				mockQuestions: action.payload.questions,
				questionCount: action.questionCount,
				examData: action.currentExam
			};

		case MOCK_EXAM_QUESTIONS_FAIL:
			return {
				mloading: false,
				error: action.payload
			};

		case CLEAR_ERRORS:
			return {
				...state,
				error: null
			};

		default:
			return state;
	}
};
