import {

    LOAD_ALL_SUBSCRIBE_NEWSLETTER_REQUEST,
    LOAD_ALL_SUBSCRIBE_NEWSLETTER_SUCCESS,
    LOAD_ALL_SUBSCRIBE_NEWSLETTER_FAIL,

    ADD_SUBSCRIBE_NEWSLETTER_REQUEST,
    ADD_SUBSCRIBE_NEWSLETTER_SUCCESS,
    ADD_SUBSCRIBE_NEWSLETTER_FAIL,


    DELETE_SUBSCRIBE_NEWSLETTER_REQUEST,
    DELETE_SUBSCRIBE_NEWSLETTER_SUCCESS,
    DELETE_SUBSCRIBE_NEWSLETTER_FAIL,

    CLEAR_ERRORS
} from '../constants/adminSubscribeNewsletterConstants';

export const subscribeNewsletterReducer = (state = { subscribeNesletter: [] }, action) => {

    switch (action.type) {

        case LOAD_ALL_SUBSCRIBE_NEWSLETTER_REQUEST:
            return {
                user_loading: true,
                subscribeNesletter: []
            }

        case LOAD_ALL_SUBSCRIBE_NEWSLETTER_SUCCESS:
            return {
                user_loading: false,
                subscribeNesletter: action.payload.data
            }

        case LOAD_ALL_SUBSCRIBE_NEWSLETTER_FAIL:
            return {
                user_loading: false,
                error: action.payload
            }

        

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }

}
export const addsubscribeNesletterReducer = (state = { subscribeNesletter: {} }, action) => {

    switch (action.type) {
        case ADD_SUBSCRIBE_NEWSLETTER_REQUEST:
            return {
                user_loading: true,
                errors: null,
            };

        case ADD_SUBSCRIBE_NEWSLETTER_SUCCESS:
            return {
                ...state,
                user_loading: false,
                subscribeNesletter: action.payload,
                errors: null,
            };


        case ADD_SUBSCRIBE_NEWSLETTER_FAIL:
            return {
                ...state,
                user_loading: false,
                subscribeNesletter: null,
                error: action.payload,
                subscribeNesletterErrors: action.errors
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }

};


export const deleteSubscribeNesletterReducer = (state = {}, action) => {

    switch (action.type) {

        case DELETE_SUBSCRIBE_NEWSLETTER_REQUEST:
            return {
                user_loading: true
            }

        case DELETE_SUBSCRIBE_NEWSLETTER_SUCCESS:
            return state.subscribeNesletter.filter(item => String(item.id) !== String(action.payload));

        case DELETE_SUBSCRIBE_NEWSLETTER_FAIL:
            return {
                user_loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }

}

