import {

    LOAD_ALL_CONTACTUS_REQUEST,
    LOAD_ALL_CONTACTUS_SUCCESS,
    LOAD_ALL_CONTACTUS_FAIL,

    ADD_CONTACTUS_REQUEST,
    ADD_CONTACTUS_SUCCESS,
    ADD_CONTACTUS_FAIL,

    CONTACTUS_DETAIL_REQUEST,
    CONTACTUS_DETAIL_SUCCESS,
    CONTACTUS_DETAIL_FAIL,

    CONTACTUS_REPLY_DETAIL_REQUEST,
    CONTACTUS_REPLY_DETAIL_SUCCESS,
    CONTACTUS_REPLY_DETAIL_FAIL,

    REPLY_CONTACTUS_REQUEST,
    REPLY_CONTACTUS_SUCCESS,
    REPLY_CONTACTUS_FAIL,

    UPDATE_CONTACTUS_REQUEST,
    UPDATE_CONTACTUS_SUCCESS,
    UPDATE_CONTACTUS_RESET,
    UPDATE_CONTACTUS_FAIL,

    DELETE_CONTACTUS_REQUEST,
    DELETE_CONTACTUS_SUCCESS,
    DELETE_CONTACTUS_FAIL,

    CLEAR_ERRORS
} from './../constants/adminContactusConstants';

export const contactusReducer = (state = { contactus: [] }, action) => {

    switch (action.type) {

        case LOAD_ALL_CONTACTUS_REQUEST:
            return {
                user_loading: true,
                contactus: []
            }

        case LOAD_ALL_CONTACTUS_SUCCESS:
            return {
                user_loading: false,
                contactus: action.payload.data
            }

        case LOAD_ALL_CONTACTUS_FAIL:
            return {
                user_loading: false,
                error: action.payload
            }

        case REPLY_CONTACTUS_REQUEST:
            return {
                ...state,
                user_loading: true,
            }

        case REPLY_CONTACTUS_SUCCESS:
            return {
                ...state,
                user_loading: false,
                isUpdated: action.payload
            }

        case REPLY_CONTACTUS_FAIL:
            return {
                ...state,
                user_loading: false,
                error: action.payload
            }

        case UPDATE_CONTACTUS_REQUEST:
            return {
                ...state,
                user_loading: true,
            }

        case UPDATE_CONTACTUS_SUCCESS:
            return {
                ...state,
                user_loading: false,
                isUpdated: action.payload
            }

        case UPDATE_CONTACTUS_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case UPDATE_CONTACTUS_FAIL:
            return {
                ...state,
                user_loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }

}
export const addContactusReducer = (state = { contactus: {} }, action) => {

    switch (action.type) {
        case ADD_CONTACTUS_REQUEST:
            return {
                user_loading: true,
                isCategoryAdded: false,
                errors: null,
            };

        case ADD_CONTACTUS_SUCCESS:
            return {
                ...state,
                user_loading: false,
                contactus: action.payload,
                errors: null,
            };


        case ADD_CONTACTUS_FAIL:
            return {
                ...state,
                user_loading: false,
                contactus: null,
                error: action.payload,
                ContactusErrors: action.errors
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }

};
export const contactusDetailReducer = (state = { contactus: {} }, action) => {

    switch (action.type) {

        case CONTACTUS_DETAIL_REQUEST:
            return {
                ...state,
                user_loading: true
            }

        case CONTACTUS_DETAIL_SUCCESS:
            return {
                user_loading: true,
                contactus: action.payload
            }

        case CONTACTUS_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload
            }


        case ADD_CONTACTUS_REQUEST:
            return {
                user_loading: true,
                isCategoryAdded: false,
                errors: null,
            };

        case ADD_CONTACTUS_SUCCESS:
            return {
                ...state,
                user_loading: false,
                contactus: action.payload,
                errors: null,
            };


        case ADD_CONTACTUS_FAIL:
            return {
                ...state,
                user_loading: false,
                contactus: null,
                error: action.payload,
                ContactusErrors: action.errors
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }

};

export const deleteContactusReducer = (state = {}, action) => {

    switch (action.type) {

        case DELETE_CONTACTUS_REQUEST:
            return {
                user_loading: true
            }

        case DELETE_CONTACTUS_SUCCESS:
            return state.contactus.filter(item => String(item.id) !== String(action.payload));

        case DELETE_CONTACTUS_FAIL:
            return {
                user_loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }

}

export const contactusReplyDetailReducer = (state = {}, action) => {

    switch (action.type) {

        case CONTACTUS_REPLY_DETAIL_REQUEST:
            return {
                ...state,
                user_loading: true
            }

        case CONTACTUS_REPLY_DETAIL_SUCCESS:
            return {
                user_loading: true,
                contactus: action.payload
            }

        case CONTACTUS_REPLY_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }

}