import {
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_EXAM_TYPES_REQUEST,
  GET_EXAM_TYPES_SUCCESS,
  GET_EXAM_TYPES_FAIL,
  GET_COURSES_REQUEST,
  GET_COURSES_SUCCESS,
  GET_COURSES_FAIL,
  GET_SUBJECTS_REQUEST,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_FAIL,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CLEAR_ERRORS,
} from "../constants/commonConstants";

export const commonReducer = (state = { countries: {} }, action) => {
  switch (action.type) {
    case GET_COUNTRIES_REQUEST:
      return {
        user_loading: true,
        countries: [],
      };

    case GET_COUNTRIES_SUCCESS:
      return {
        user_loading: false,
        countries: action.payload,
      };

    case GET_COUNTRIES_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const commonCategoriesReducer = (
  state = { allCategories: {} },
  action
) => {
  switch (action.type) {
    case GET_CATEGORIES_REQUEST:
      return {
        loading: true,
        allCategories: [],
      };

    case GET_CATEGORIES_SUCCESS:
      return {
        loading: false,
        allCategories: action.payload,
      };

    case GET_CATEGORIES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const commonExamsReducer = (state = { allExamTypes: {} }, action) => {
  switch (action.type) {
    case GET_EXAM_TYPES_REQUEST:
      return {
        eloading: true,
        allExamTypes: [],
      };

    case GET_EXAM_TYPES_SUCCESS:
      return {
        eloading: false,
        allExamTypes: action.payload,
      };

    case GET_EXAM_TYPES_FAIL:
      return {
        eloading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const commonCoursesReducer = (state = { allCourses: {} }, action) => {
  switch (action.type) {
    case GET_COURSES_REQUEST:
      return {
        loading: true,
        allCourses: [],
      };

    case GET_COURSES_SUCCESS:
      return {
        loading: false,
        allCourses: action.payload,
      };

    case GET_COURSES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const commonSubjectsReducer = (state = { allSubjects: {} }, action) => {
  switch (action.type) {
    case GET_SUBJECTS_REQUEST:
      return {
        loading: true,
        allSubjects: [],
      };

    case GET_SUBJECTS_SUCCESS:
      return {
        loading: false,
        allSubjects: action.payload,
      };

    case GET_SUBJECTS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const getUserDataReducer = (state = { userData: {} }, action) => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return {
        user_loading: true,
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        user_loading: false,
        userData: action.payload,
      };

    case GET_USER_FAIL:
      return {
        user_loading: false,
        userData: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const deleteUserReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_USER_REQUEST:
      return {
        user_loading: true,
      };

    case DELETE_USER_SUCCESS:
      return state.users.filter(
        (item) => String(item.id) !== String(action.payload)
      );
    /*return [
                ...state,
                state.filter(item => item !== action.item)
            ]*/

    case DELETE_USER_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
