import {
  LOAD_ALL_SUBSCRIPTIONS_REQUEST,
  LOAD_ALL_SUBSCRIPTIONS_SUCCESS,
  LOAD_ALL_SUBSCRIPTIONS_FAIL,
  ADD_SUBSCRIPTION_REQUEST,
  ADD_SUBSCRIPTION_SUCCESS,
  ADD_SUBSCRIPTION_RESET,
  ADD_SUBSCRIPTION_FAIL,
  SUBSCRIPTION_DETAIL_REQUEST,
  SUBSCRIPTION_DETAIL_SUCCESS,
  SUBSCRIPTION_DETAIL_FAIL,
  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_RESET,
  UPDATE_SUBSCRIPTION_FAIL,
  DELETE_SUBSCRIPTION_REQUEST,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAIL,
  /**
   * List student subscriptions
   */
  LIST_STUDENT_SUBSCRIPTIONS_REQUEST,
  LIST_STUDENT_SUBSCRIPTIONS_SUCCESS,
  LIST_STUDENT_SUBSCRIPTIONS_FAIL,
  /**
   * List student subscriptions
   */
  CLEAR_ERRORS,
} from "../constants/adminSubscriptionConstants";

export const subscriptionsReducer = (
  state = { subscriptions: [], studentSubscriptions: [] },
  action
) => {
  switch (action.type) {
    case LOAD_ALL_SUBSCRIPTIONS_REQUEST:
      return {
        user_loading: true,
        subscriptions: [],
      };

    case LOAD_ALL_SUBSCRIPTIONS_SUCCESS:
      return {
        user_loading: false,
        subscriptions: action.payload.data,
      };

    case LOAD_ALL_SUBSCRIPTIONS_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case UPDATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        user_loading: true,
      };

    case UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        user_loading: false,
        isUpdated: action.payload,
      };

    case UPDATE_SUBSCRIPTION_RESET:
      return {
        ...state,
        isUpdated: false,
      };

    case UPDATE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        user_loading: false,
        error: action.payload,
      };

    /**
     * Load Students Subscription
     */
    case LIST_STUDENT_SUBSCRIPTIONS_REQUEST:
      return {
        sloading: true,
        studentSubscriptions: [],
      };

    case LIST_STUDENT_SUBSCRIPTIONS_SUCCESS:
      return {
        sloading: false,
        studentSubscriptions: action.payload.subscriptions,
      };

    case LIST_STUDENT_SUBSCRIPTIONS_FAIL:
      return {
        sloading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const subscriptionDetailReducer = (
  state = { subscription: {} },
  action
) => {
  switch (action.type) {
    case SUBSCRIPTION_DETAIL_REQUEST:
      return {
        ...state,
        user_loading: true,
      };

    case SUBSCRIPTION_DETAIL_SUCCESS:
      return {
        user_loading: true,
        subscription: action.payload,
      };

    case SUBSCRIPTION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_SUBSCRIPTION_REQUEST:
      return {
        sloading: true,
        isSubsAdded: false,
        errors: null,
      };

    case ADD_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        sloading: false,
        isSubsAdded: true,
        errors: null,
      };

    case ADD_SUBSCRIPTION_RESET:
      return {
        ...state,
        isSubsAdded: false,
        errors: null,
      };

    case ADD_SUBSCRIPTION_FAIL:
      return {
        ...state,
        sloading: false,
        isSubsAdded: false,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const deleteSubscriptionReducer = (state, action) => {
  switch (action.type) {
    case DELETE_SUBSCRIPTION_REQUEST:
      return {
        user_loading: true,
      };

    case DELETE_SUBSCRIPTION_SUCCESS:
      return [
        ...state,
        state.subscriptions.filter((item) => item !== action.item),
      ];

    case DELETE_SUBSCRIPTION_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
