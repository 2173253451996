import {
  LOAD_ALL_ASSESSMENTS_REQUEST,
  LOAD_ALL_ASSESSMENTS_SUCCESS,
  LOAD_ALL_ASSESSMENTS_FAIL,
  ADD_ASSESSMENT_REQUEST,
  ADD_ASSESSMENT_SUCCESS,
  ADD_ASSESSMENT_RESET,
  ADD_ASSESSMENT_FAIL,
  ASSESSMENT_DETAIL_REQUEST,
  ASSESSMENT_DETAIL_SUCCESS,
  ASSESSMENT_DETAIL_FAIL,
  UPDATE_ASSESSMENT_REQUEST,
  UPDATE_ASSESSMENT_SUCCESS,
  UPDATE_ASSESSMENT_RESET,
  UPDATE_ASSESSMENT_FAIL,
  DELETE_ASSESSMENT_REQUEST,
  DELETE_ASSESSMENT_SUCCESS,
  DELETE_ASSESSMENT_FAIL,
  CLEAR_ERRORS,
} from "./../constants/adminAssessmentConstants";

export const assessmentsReducer = (state = { assessments: [] }, action) => {
  switch (action.type) {
    case LOAD_ALL_ASSESSMENTS_REQUEST:
      return {
        user_loading: true,
        assessments: [],
      };

    case LOAD_ALL_ASSESSMENTS_SUCCESS:
      return {
        user_loading: false,
        assessments: action.payload.data,
      };

    case LOAD_ALL_ASSESSMENTS_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case UPDATE_ASSESSMENT_REQUEST:
      return {
        ...state,
        user_loading: true,
        errors: null,
      };

    case UPDATE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        user_loading: false,
        isUpdated: action.payload,
      };

    case UPDATE_ASSESSMENT_RESET:
      return {
        ...state,
        isUpdated: false,
        errors: null,
      };

    case UPDATE_ASSESSMENT_FAIL:
      return {
        ...state,
        user_loading: false,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const assessmentDetailReducer = (state = { assessment: {} }, action) => {
  switch (action.type) {
    case ASSESSMENT_DETAIL_REQUEST:
      return {
        ...state,
        user_loading: true,
      };

    case ASSESSMENT_DETAIL_SUCCESS:
      return {
        user_loading: true,
        assessment: action.payload,
      };

    case ASSESSMENT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_ASSESSMENT_REQUEST:
      return {
        user_loading: true,
        isAssessmentAdded: false,
        errors: null,
      };

    case ADD_ASSESSMENT_SUCCESS:
      return {
        ...state,
        user_loading: false,
        isAssessmentAdded: true,
        assessment: action.payload,
        errors: null,
      };

    case ADD_ASSESSMENT_RESET:
      return {
        ...state,
        isAssessmentAdded: false,
        errors: null,
      };

    case ADD_ASSESSMENT_FAIL:
      return {
        ...state,
        user_loading: false,
        isAssessmentAdded: false,
        assessment: null,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const deleteAssessmentReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ASSESSMENT_REQUEST:
      return {
        user_loading: true,
      };

    case DELETE_ASSESSMENT_SUCCESS:
      return state.filter((item) => String(item.id) !== String(action.payload));

    case DELETE_ASSESSMENT_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
