export const LOAD_ALL_ASSESSMENTS_REQUEST = "LOAD_ALL_ASSESSMENTS_REQUEST";
export const LOAD_ALL_ASSESSMENTS_SUCCESS = "LOAD_ALL_ASSESSMENTS_SUCCESS";
export const LOAD_ALL_ASSESSMENTS_FAIL = "LOAD_ALL_ASSESSMENTS_FAIL";

export const ASSESSMENT_DETAIL_REQUEST = "ASSESSMENT_DETAIL_REQUEST";
export const ASSESSMENT_DETAIL_SUCCESS = "ASSESSMENT_DETAIL_SUCCESS";
export const ASSESSMENT_DETAIL_FAIL = "ASSESSMENT_DETAIL_FAIL";

export const ADD_ASSESSMENT_REQUEST = "ADD_ASSESSMENT_REQUEST";
export const ADD_ASSESSMENT_SUCCESS = "ADD_ASSESSMENT_SUCCESS";
export const ADD_ASSESSMENT_RESET = "ADD_ASSESSMENT_RESET";
export const ADD_ASSESSMENT_FAIL = "ADD_ASSESSMENT_FAIL";

export const UPDATE_ASSESSMENT_REQUEST = "UPDATE_ASSESSMENT_REQUEST";
export const UPDATE_ASSESSMENT_SUCCESS = "UPDATE_ASSESSMENT_SUCCESS";
export const UPDATE_ASSESSMENT_RESET = "UPDATE_ASSESSMENT_RESET";
export const UPDATE_ASSESSMENT_FAIL = "UPDATE_ASSESSMENT_FAIL";
export const DELETE_ASSESSMENT_REQUEST = "DELETE_ASSESSMENT_REQUEST";
export const DELETE_ASSESSMENT_SUCCESS = "DELETE_ASSESSMENT_SUCCESS";
export const DELETE_ASSESSMENT_FAIL = "DELETE_ASSESSMENT_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
