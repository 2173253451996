import {
  GET_ALL_NEWS_REQUEST,
  GET_ALL_NEWS_SUCCESS,
  GET_ALL_NEWS_FAIL,
  GET_NEWS_REQUEST,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAIL,
  CLEAR_ERRORS,
} from "./../constants/newsConstants";

export const allNewsListReducer = (state = { news: [] }, action) => {
  switch (action.type) {
    case GET_ALL_NEWS_REQUEST:
      return {
        nloading: true,
        news: [],
      };

    case GET_ALL_NEWS_SUCCESS:
      return {
        nloading: false,
        news: action.payload.data,
      };

    case GET_ALL_NEWS_FAIL:
      return {
        nloading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const singleNewsReducer = (state = { newsDetail: {} }, action) => {
  switch (action.type) {
    case GET_NEWS_REQUEST:
      return {
        ...state,
        nloading: true,
      };

    case GET_NEWS_SUCCESS:
      return {
        nloading: true,
        newsDetail: action.payload,
      };

    case GET_NEWS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
