export const LOAD_ALL_TESTIMONIAL_REQUEST = "LOAD_ALL_TESTIMONIAL_REQUEST";
export const LOAD_ALL_TESTIMONIAL_SUCCESS = "LOAD_ALL_TESTIMONIAL_SUCCESS";
export const LOAD_ALL_TESTIMONIAL_FAIL = "LOAD_ALL_TESTIMONIAL_FAIL";

export const TESTIMONIAL_DETAIL_REQUEST = "TESTIMONIAL_DETAIL_REQUEST";
export const TESTIMONIAL_DETAIL_SUCCESS = "TESTIMONIAL_DETAIL_SUCCESS";
export const TESTIMONIAL_DETAIL_FAIL = "TESTIMONIAL_DETAIL_FAIL";

export const ADD_TESTIMONIAL_REQUEST = "ADD_TESTIMONIAL_REQUEST";
export const ADD_TESTIMONIAL_SUCCESS = "ADD_TESTIMONIAL_SUCCESS";
export const ADD_TESTIMONIAL_RESET = "ADD_TESTIMONIAL_RESET";
export const ADD_TESTIMONIAL_FAIL = "ADD_TESTIMONIAL_FAIL";

export const UPDATE_TESTIMONIAL_REQUEST = "UPDATE_TESTIMONIAL_REQUEST";
export const UPDATE_TESTIMONIAL_SUCCESS = "UPDATE_TESTIMONIAL_SUCCESS";
export const UPDATE_TESTIMONIAL_RESET = "UPDATE_TESTIMONIAL_RESET";
export const UPDATE_TESTIMONIAL_FAIL = "UPDATE_TESTIMONIAL_FAIL";

export const DELETE_TESTIMONIAL_REQUEST = "DELETE_TESTIMONIAL_REQUEST";
export const DELETE_TESTIMONIAL_SUCCESS = "DELETE_TESTIMONIAL_SUCCESS";
export const DELETE_TESTIMONIAL_FAIL = "DELETE_TESTIMONIAL_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
