export const LOAD_ALL_THEORY_REQUEST = "LOAD_ALL_THEORY_REQUEST";
export const LOAD_ALL_THEORY_SUCCESS = "LOAD_ALL_THEORY_SUCCESS";
export const LOAD_ALL_THEORY_FAIL = "LOAD_ALL_THEORY_FAIL";

export const THEORY_DETAIL_REQUEST = "THEORY_DETAIL_REQUEST";
export const THEORY_DETAIL_SUCCESS = "THEORY_DETAIL_SUCCESS";
export const THEORY_DETAIL_FAIL = "THEORY_DETAIL_FAIL";

export const ADD_THEORY_REQUEST = "ADD_THEORY_REQUEST";
export const ADD_THEORY_SUCCESS = "ADD_THEORY_SUCCESS";
export const ADD_THEORY_RESET = "ADD_THEORY_RESET";
export const ADD_THEORY_FAIL = "ADD_THEORY_FAIL";

export const UPDATE_THEORY_REQUEST = "UPDATE_THEORY_REQUEST";
export const UPDATE_THEORY_SUCCESS = "UPDATE_THEORY_SUCCESS";
export const UPDATE_THEORY_RESET = "UPDATE_THEORY_RESET";
export const UPDATE_THEORY_FAIL = "UPDATE_THEORY_FAIL";

export const DELETE_THEORY_REQUEST = "DELETE_THEORY_REQUEST";
export const DELETE_THEORY_SUCCESS = "DELETE_THEORY_SUCCESS";
export const DELETE_THEORY_FAIL = "DELETE_THEORY_FAIL";

export const GET_THEORY_EXAM_REQUEST = "GET_THEORY_EXAM_REQUEST";
export const GET_THEORY_EXAM_SUCCESS = "GET_THEORY_EXAM_SUCCESS";
export const GET_THEORY_EXAM_FAIL = "GET_THEORY_EXAM_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
