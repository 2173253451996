import React, {Fragment,useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAlert } from 'react-alert';

import { useDispatch, useSelector } from 'react-redux';
import { getContactusDetail, replyContactus } from '../../../actions/adminContactusActions';

import Loader from "../../Loader";

import $ from "jquery";
import "bootstrap/dist/css/bootstrap.css";

import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles

import "bootstrap/dist/js/bootstrap.js";

import "./../RichTextEditor.css";
import ContactusReply from './ContactusReply';

window.jQuery = $;
require("bootstrap");

const ViewContactus = ({ history, match }) => {

    const alert = useAlert();
    const dispatch  = useDispatch();
    const [replyMessage, setReplyMessage] = useState("");

    const contactusId = match.params.id;

    const onEditorDataChange = (contentData) => {
        setReplyMessage(contentData);
      };
    
    useEffect(() => {
        dispatch(
            getContactusDetail(contactusId)
        )
    }, [dispatch,contactusId]);

    const { contactusDetail, user_loading, error } = useSelector(state => state);

const handleContactusReply = (e) => {
    e.preventDefault();
    if(replyMessage ==="<p><br></p>" || replyMessage === ""){
        alert.error("Please enter reply message.");
        return false;
    }
    dispatch(replyContactus(contactusId, replyMessage));
    
    if(error){
      alert.error(error.replyMessage);
    }else{
      alert.success("Message replied successfully.");
      window.location.reload();
    }
}  

    return (
        <Fragment>
            {user_loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <h1>
                                Manage
                                <small>Contact Us</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li><Link to="/admin"><i className="fa fa-dashboard"></i> Dashboard</Link></li>
                                <li><Link to="/admin/contactus"><i className="fa fa-list"></i> Contact Us</Link></li>
                                <li className="active">Contact Us Details</li>
                            </ol>
                        </section>

                     

                                        <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Contact Us Details</h3>

                </div>
                <form onSubmit={handleContactusReply}>
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="page_title">Name:&nbsp; </label>
                         {contactusDetail.contactus.firstName} {contactusDetail.contactus.lastName}
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="mobileNo">Mobile No:&nbsp; </label>
                         {contactusDetail.contactus.mobileNo}
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="Email">Email:&nbsp; </label>
                         {contactusDetail.contactus.email}
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="Subject">Subject:&nbsp; </label>
                         {contactusDetail.contactus.subject} 
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="Message">Message:&nbsp; </label>
                         {contactusDetail.contactus.message} 
                      </div>
                    </div>
                    <div className="col-md-12">
                      <ContactusReply id={contactusId}></ContactusReply>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="replyMessage">Reply Message<sup>*</sup></label>
                        <ReactSummernote
                            value={replyMessage}
                            id="replyMessage"
                            name="replyMessage"
                            options={{
                              lang: "en-US",
                              height: 250,
                              dialogsInBody: false,
                              toolbar: [
                                ["style", ["style"]],
                                [
                                  "font",
                                  ["bold", "italic", "underline", "clear"],
                                ],
                                ["fontname", ["fontname"]],
                                ["color", ["color"]],
                                ["para", ["ul", "ol", "listStyles", "paragraph"]],
                                ["table", ["table"]],
                                ["view", ["fullscreen", "codeview"]],
                              ],
                            }}
                            onChange={onEditorDataChange}
                          
                        />
                        {/*) : (
                          ""
                        )}*/}
                      </div>
                    </div>
                    
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Reply
                    </button>
                  </div>
                </form>
              </div>
            </section>
                                   
                           
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default ViewContactus;
