import {
  GET_ASSESSMENTS_REQUEST,
  GET_ASSESSMENTS_SUCCESS,
  GET_ASSESSMENTS_FAIL,
  ASSESSMENT_REQUEST,
  ASSESSMENT_SUCCESS,
  ASSESSMENT_FAIL,
  CLEAR_ERRORS,
} from "./../constants/assessmentConstants";

export const assessmentsListReducer = (state = { assessments: [] }, action) => {
  switch (action.type) {
    case GET_ASSESSMENTS_REQUEST:
      return {
        aloading: true,
        assessments: [],
      };

    case GET_ASSESSMENTS_SUCCESS:
      return {
        aloading: false,
        assessments: action.payload.data,
      };

    case GET_ASSESSMENTS_FAIL:
      return {
        aloading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

//get single assessment
export const singleAssessmentReducer = (
  state = { assessmentData: "" },
  action
) => {
  switch (action.type) {
    case ASSESSMENT_REQUEST:
      return {
        ...state,
        aloading: true,
      };

    case ASSESSMENT_SUCCESS:
      return {
        aloading: true,
        assessmentData: action.payload,
      };

    case ASSESSMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
