export const SUBMIT_RESULT_REQUEST = "SUBMIT_RESULT_REQUEST";
export const SUBMIT_RESULT_SUCCESS = "SUBMIT_RESULT_SUCCESS";
export const SUBMIT_RESULT_RESET = "SUBMIT_RESULT_RESET";
export const SUBMIT_RESULT_FAIL = "SUBMIT_RESULT_FAIL";

export const SUBMIT_GRADE_RESULT_REQUEST = "SUBMIT_GRADE_RESULT_REQUEST";
export const SUBMIT_GRADE_RESULT_SUCCESS = "SUBMIT_GRADE_RESULT_SUCCESS";
export const SUBMIT_GRADE_RESULT_RESET = "SUBMIT_GRADE_RESULT_RESET";
export const SUBMIT_GRADE_RESULT_FAIL = "SUBMIT_GRADE_RESULT_FAIL";

export const GET_USER_RESULTS_REQUEST = "GET_USER_RESULTS_REQUEST";
export const GET_USER_RESULTS_SUCCESS = "GET_USER_RESULTS_SUCCESS";
export const GET_USER_RESULTS_FAIL = "GET_USER_RESULTS_FAIL";

export const GET_RESULT_REQUEST = "GET_RESULT_REQUEST";
export const GET_RESULT_SUCCESS = "GET_RESULT_SUCCESS";
export const GET_RESULT_FAIL = "GET_RESULT_FAIL";

export const GET_RESULT_VIEW_REQUEST = "GET_RESULT_VIEW_REQUEST";
export const GET_RESULT_VIEW_SUCCESS = "GET_RESULT_VIEW_SUCCESS";
export const GET_RESULT_VIEW_FAIL = "GET_RESULT_VIEW_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
