import {
  CATEGORY_COURSES_REQUEST,
  CATEGORY_COURSES_SUCCESS,
  CATEGORY_COURSES_FAIL,
  CLEAR_ERRORS,
} from "./../constants/courseConstants";

export const categoryCoursesReducer = (state = { courses: [] }, action) => {
  switch (action.type) {
    case CATEGORY_COURSES_REQUEST:
      return {
        cloading: true,
        courses: [],
      };

    case CATEGORY_COURSES_SUCCESS:
      return {
        cloading: false,
        courses: action.payload.data,
      };

    case CATEGORY_COURSES_FAIL:
      return {
        cloading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
