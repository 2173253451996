import {
  LOAD_ALL_CUSTOMERS_REQUEST,
  LOAD_ALL_CUSTOMERS_SUCCESS,
  LOAD_ALL_CUSTOMERS_FAIL,
  ADD_STUDENT_REQUEST,
  ADD_STUDENT_SUCCESS,
  ADD_STUDENT_RESET,
  ADD_STUDENT_FAIL,
  DELETE_STUDENT_SUCCESS,
  DELETE_STUDENT_REQUEST,
  DELETE_STUDENT_FAIL,
  CLEAR_ERRORS,
} from "../constants/adminStudentsConstants";

export const studentsReducer = (state = { students: {} }, action) => {
  switch (action.type) {
    case LOAD_ALL_CUSTOMERS_REQUEST:
      return {
        user_loading: true,
        students: [],
      };

    case LOAD_ALL_CUSTOMERS_SUCCESS:
      return {
        user_loading: false,
        students: action.payload.customers,
      };

    case LOAD_ALL_CUSTOMERS_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const addStudentReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case ADD_STUDENT_REQUEST:
      return {
        user_loading: true,
        isAdded: false,
        errors: null,
      };

    case ADD_STUDENT_SUCCESS:
      return {
        ...state,
        user_loading: false,
        isAdded: true,
        user: action.payload,
        errors: null,
      };

    case ADD_STUDENT_RESET:
      return {
        ...state,
        isAdded: false,
      };

    case ADD_STUDENT_FAIL:
      return {
        ...state,
        user_loading: false,
        isAdded: false,
        user: null,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const deleteStudentReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_STUDENT_REQUEST:
      return {
        user_loading: true,
      };

    case DELETE_STUDENT_SUCCESS:
      return state.students.filter(
        (item) => String(item.id) !== String(action.payload)
      );

    case DELETE_STUDENT_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
