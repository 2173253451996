import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "./actions/authActions";
import AppRouter from "./AppRouter";

import Maintenance from './Maintenance';

import "./App.css";

function App() {
  const dispatch = useDispatch();
  //const { isUpdated, user_loading, error } = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  if (process.env.REACT_APP_MAINTENANCE_MODE !== false) {
    return <Maintenance />;
  }

  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  );
}

export default App;
