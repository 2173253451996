export const LOAD_ALL_COURSES_REQUEST = "LOAD_ALL_COURSES_REQUEST";
export const LOAD_ALL_COURSES_SUCCESS = "LOAD_ALL_COURSES_SUCCESS";
export const LOAD_ALL_COURSES_FAIL = "LOAD_ALL_COURSES_FAIL";

export const COURSE_DETAIL_REQUEST = "COURSE_DETAIL_REQUEST";
export const COURSE_DETAIL_SUCCESS = "COURSE_DETAIL_SUCCESS";
export const COURSE_DETAIL_FAIL = "COURSE_DETAIL_FAIL";

export const ADD_COURSE_REQUEST = "ADD_COURSE_REQUEST";
export const ADD_COURSE_SUCCESS = "ADD_COURSE_SUCCESS";
export const ADD_COURSE_RESET = "ADD_COURSE_RESET";
export const ADD_COURSE_FAIL = "ADD_COURSE_FAIL";

export const UPDATE_COURSE_REQUEST = "UPDATE_COURSE_REQUEST";
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";
export const UPDATE_COURSE_RESET = "UPDATE_COURSE_RESET";
export const UPDATE_COURSE_FAIL = "UPDATE_COURSE_FAIL";
export const DELETE_COURSE_REQUEST = "DELETE_COURSE_REQUEST";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_FAIL = "DELETE_COURSE_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
