export const GET_EXAM_SUBSCRIPTIONS_REQUEST = "GET_EXAM_SUBSCRIPTIONS_REQUEST";
export const GET_EXAM_SUBSCRIPTIONS_SUCCESS = "GET_EXAM_SUBSCRIPTIONS_SUCCESS";
export const GET_EXAM_SUBSCRIPTIONS_FAIL = "GET_EXAM_SUBSCRIPTIONS_FAIL";

export const USER_SUBSCRIPTION_REQUEST = "USER_SUBSCRIPTION_REQUEST";
export const USER_SUBSCRIPTION_SUCCESS = "USER_SUBSCRIPTION_SUCCESS";
export const USER_SUBSCRIPTION_FAIL = "USER_SUBSCRIPTION_FAIL";

export const GET_SUBSCRIPTION_REQUEST = "GET_SUBSCRIPTION_REQUEST";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAIL = "GET_SUBSCRIPTION_FAIL";

export const PLAN_SUBSCRIPTION_REQUEST = "PLAN_SUBSCRIPTION_REQUEST";
export const PLAN_SUBSCRIPTION_SUCCESS = "PLAN_SUBSCRIPTION_SUCCESS";
export const PLAN_SUBSCRIPTION_FAIL = "PLAN_SUBSCRIPTION_FAIL";

export const USER_SUBSCRIPTION_LIST_REQUEST = "USER_SUBSCRIPTION_LIST_REQUEST";
export const USER_SUBSCRIPTION_LIST_SUCCESS = "USER_SUBSCRIPTION_LIST_SUCCESS";
export const USER_SUBSCRIPTION_LIST_FAIL = "USER_SUBSCRIPTION_LIST_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
