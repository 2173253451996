import {
    LOAD_ALL_CMS_REQUEST,
    LOAD_ALL_CMS_SUCCESS,
    LOAD_ALL_CMS_FAIL,

    CMS_DETAIL_REQUEST,
    CMS_DETAIL_SUCCESS,
    CMS_DETAIL_FAIL,

    UPDATE_CMS_REQUEST,
    UPDATE_CMS_SUCCESS,
    UPDATE_CMS_RESET,
    UPDATE_CMS_FAIL,

    LOAD_CMS_PAGE_REQUEST,
    LOAD_CMS_PAGE_SUCCESS,
    LOAD_CMS_PAGE_FAIL,
    CLEAR_ERRORS
} from '../constants/cmsConstants'

export const cmsListReducer = (state = { pages: {page_loading: true, pages: []} }, action) => {

    switch (action.type) {

        case LOAD_ALL_CMS_REQUEST:
            return {
                page_loading: true,
                pages: []
            }

        case LOAD_ALL_CMS_SUCCESS:
            return {
                page_loading: false,
                pages: action.payload.data
            }

        case LOAD_ALL_CMS_FAIL:
            return {
                page_loading: false,
                error: action.payload
            }


        case UPDATE_CMS_REQUEST:
            return {
                ...state,
                page_loading: true,
            };

        case UPDATE_CMS_SUCCESS:
            return {
                ...state,
                page_loading: false,
                isPageUpdated: action.payload,
            };

        case UPDATE_CMS_RESET:
            return {
                ...state,
                isPageUpdated: false,
            };

        case UPDATE_CMS_FAIL:
            return {
                ...state,
                page_loading: false,
                error: action.payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }

};

export const cmsDetailReducer = (
    state = { page: {} },
    action
) => {
    switch (action.type) {
        case CMS_DETAIL_REQUEST:
            return {
                ...state,
                page_loading: true,
            };

        case CMS_DETAIL_SUCCESS:
            return {
                page_loading: true,
                page: action.payload,
            };

        case CMS_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const cmsReducer = (state = { }, action) => {
    
    switch (action.type) {

        case LOAD_CMS_PAGE_REQUEST:
            return {
                page_loading: true,
                staticpage: null
            }

        case LOAD_CMS_PAGE_SUCCESS:
            return {
                page_loading: false,
                staticpage: action.payload
            }

        case LOAD_CMS_PAGE_FAIL:
            return {
                page_loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }

}
