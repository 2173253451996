export const LOAD_ALL_CUSTOMERS_REQUEST = 'LOAD_ALL_CUSTOMERS_REQUEST';
export const LOAD_ALL_CUSTOMERS_SUCCESS = 'LOAD_ALL_CUSTOMERS_SUCCESS';
export const LOAD_ALL_CUSTOMERS_FAIL = 'LOAD_ALL_CUSTOMERS_FAIL';

export const DELETE_STUDENT_SUCCESS = 'DELETE_STUDENT_SUCCESS';
export const DELETE_STUDENT_REQUEST = 'DELETE_STUDENT_REQUEST';
export const DELETE_STUDENT_FAIL = 'DELETE_STUDENT_FAIL';

export const ADD_STUDENT_REQUEST = 'ADD_STUDENT_REQUEST';
export const ADD_STUDENT_SUCCESS = 'ADD_STUDENT_SUCCESS';
export const ADD_STUDENT_RESET = 'ADD_STUDENT_RESET';
export const ADD_STUDENT_FAIL = 'ADD_STUDENT_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';