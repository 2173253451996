import {
  LOAD_ALL_TOPICS_REQUEST,
  LOAD_ALL_TOPICS_SUCCESS,
  LOAD_ALL_TOPICS_FAIL,
  ADD_TOPIC_REQUEST,
  ADD_TOPIC_SUCCESS,
  ADD_TOPIC_RESET,
  ADD_TOPIC_FAIL,
  TOPIC_DETAIL_REQUEST,
  TOPIC_DETAIL_SUCCESS,
  TOPIC_DETAIL_FAIL,
  UPDATE_TOPIC_REQUEST,
  UPDATE_TOPIC_SUCCESS,
  UPDATE_TOPIC_RESET,
  UPDATE_TOPIC_FAIL,
  DELETE_TOPIC_REQUEST,
  DELETE_TOPIC_SUCCESS,
  DELETE_TOPIC_FAIL,
  CLEAR_ERRORS,
} from "./../constants/adminTopicConstants";

export const topicsReducer = (state = { topics: [] }, action) => {
  switch (action.type) {
    case LOAD_ALL_TOPICS_REQUEST:
      return {
        user_loading: true,
        topics: [],
      };

    case LOAD_ALL_TOPICS_SUCCESS:
      return {
        user_loading: false,
        topics: action.payload.data,
      };

    case LOAD_ALL_TOPICS_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case UPDATE_TOPIC_REQUEST:
      return {
        ...state,
        user_loading: true,
        errors: null,
      };

    case UPDATE_TOPIC_SUCCESS:
      return {
        ...state,
        user_loading: false,
        isUpdated: action.payload,
      };

    case UPDATE_TOPIC_RESET:
      return {
        ...state,
        isUpdated: false,
        errors: null,
      };

    case UPDATE_TOPIC_FAIL:
      return {
        ...state,
        user_loading: false,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const topicDetailReducer = (state = { topic: {} }, action) => {
  switch (action.type) {
    case TOPIC_DETAIL_REQUEST:
      return {
        ...state,
        user_loading: true,
      };

    case TOPIC_DETAIL_SUCCESS:
      return {
        user_loading: true,
        topic: action.payload,
      };

    case TOPIC_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_TOPIC_REQUEST:
      return {
        user_loading: true,
        isTopicAdded: false,
        errors: null,
      };

    case ADD_TOPIC_SUCCESS:
      return {
        ...state,
        user_loading: false,
        isTopicAdded: true,
        topic: action.payload,
        errors: null,
      };

    case ADD_TOPIC_RESET:
      return {
        ...state,
        isTopicAdded: false,
        errors: null,
      };

    case ADD_TOPIC_FAIL:
      return {
        ...state,
        user_loading: false,
        isTopicAdded: false,
        topic: null,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const deleteTopicReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_TOPIC_REQUEST:
      return {
        user_loading: true,
      };

    case DELETE_TOPIC_SUCCESS:
      return state.filter((item) => String(item.id) !== String(action.payload));

    case DELETE_TOPIC_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
